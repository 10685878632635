import { IEventAggregationService, ISessionService } from '@studyportals/student-interfaces';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { Actor, InterestType, StudentRepositoryStateType } from '../../../interfaces';
import { StudentRepository } from '../student-repository';

export abstract class StudentRepositoryState {
	constructor(
		public stateType: StudentRepositoryStateType,
		protected eventAggregationService: IEventAggregationService,
		protected sessionService: ISessionService,
		protected studentRepository: StudentRepository
	) {

	}

	public abstract initialize(): void;

	public abstract setStudentData(studentData: IStudent, actor: Actor): Promise<void>;
	public abstract getStudentData(studentFields: StudentField[]): Promise<IStudent>;
	public abstract addToCollection(type: StudentField, items: any[]): Promise<void>;
	public abstract removeFromCollection(type: StudentField, items: any[]): Promise<void>;

}
